import React, { useState } from 'react';
import { Document, Page,PDFViewer } from '@react-pdf/renderer'; 
import { Image, Space } from 'antd'; 

// function blobToURL(blob) {
//     return new Promise((resolve) => {
//        const reader = new FileReader();
//        reader.readAsDataURL(blob);
//        reader.onloadend = function () {
//           const base64data = reader.result;
//           resolve(base64data);
//        };
//     });
//  }

const FileRenderer = ({ item }) => {
    const [blobFile, setBlobFile] = useState("")
  // Helper function to determine the file type from URL
  const getFileType =  (url) => {
    const extension = url.split('.').pop();
    if (['pdf'].includes(extension)) {
        // const arrayBuffer = await fetch(url);
        // const blob = await arrayBuffer.blob();
        // const fileUrl = await blobToURL(blob);
        // setBlobFile(fileUrl)
      return 'pdf';
    } else if (['mp4', 'avi', 'mov', 'webm'].includes(extension)) {
      return 'video';
    } else if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'].includes(extension)) {
      return 'image';
    }
    return 'other';
  };

  const fileType = getFileType(item.url);

  return (
    <Space size={20}>
      {fileType === 'pdf' && (
        <embed
        title="test"
        src={item.url}
        style={{ width: '700px', height: '680px', marginTop: '16px' }}
        frameBorder="0"
      ></embed>
      )}

      {fileType === 'video' && (
        <video controls width="700px">
          <source src={item.url} type={`video/${item.url.split('.').pop()}`} />
          Your browser does not support the video tag.
        </video>
      )}

      {fileType === 'image' && <Image src={item.url} alt="file" width={'700px'} />}

      {fileType === 'other' && (
        <a href={item.url} download>
          Download File
        </a>
      )}

      {/* {item.title} */}
    </Space>
  );
};

export default FileRenderer;
