import React, { useEffect, useState } from 'react'

import { Avatar, Button, Card, Dropdown, Menu, Modal, notification, Table } from 'antd'
import Column from 'antd/lib/table/Column'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUsers } from '../reducers/staffReducer'
import { batchEnroll, enroll } from '../reducers/courseReducer'

const CourseImage = ({ image, backgroundColor }) => {
  if (!image)
    return (
      <div style={{ backgroundColor: `${backgroundColor}`, height: 256 }}></div>
    )

  return (
    <img
      style={{
        maxHeight: 256,
        objectFit: 'cover',
        objectPosition: 'top'
      }}
      alt="course img"
      src={image}
    />
  )
}

const CardContent = (props) => {
  const {
    description,
    enrolled,
    onEnroll,
    onUnenroll,
    loadingEnroll,
    disableEnroll,
    privilege=null,
    setStaffListModalOpen=f=>f,
    onClick=f=>f
  } = props

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {description}
      <div
        onClick={(event) => {
          event.stopPropagation()
        }}
        style={{
          marginTop: '20px',
          alignSelf: 'flex-end'
        }}
      >
        {(privilege === 'instructor' || privilege === 'admin') && (
        <Button style={{marginTop:5, marginBottom:5, marginRight: 5}} onClick={()=>setStaffListModalOpen(true)} success >
          Assign to Staff
        </Button>
      )}

{enrolled && <Button onClick={onClick} success >
          Go to course
        </Button>}
        {/* {!disableEnroll && (
          <>
            {!enrolled && (
              <Button loading={loadingEnroll} onClick={() => onEnroll()}>
                Enroll
              </Button>
            )}

            {enrolled && (
              <Button loading={loadingEnroll} onClick={() => onUnenroll()}>
                Unenroll
              </Button>
            )}
          </>
        )} */}
      </div>
    </div>
  )
}

const CourseCard = (props) => {
  const {
    course,
    onClick,
    handleEnroll,
    handleUnenroll,
    removeCourse,
    disableEnroll
  } = props
  const dispatch = useDispatch()
  const { enrolled, privilege } = course
  const [staffListModalIsOpen, setStaffListModalOpen] = useState(false)
  const staffList = useSelector((state) => state.staff.data)
  const loading = useSelector((state) => state.staff.loading)
  const user = useSelector((state) => state.auth.user)

  const [selectedStaff, setSelectedStaff] = useState([])

  useEffect(() => {
    dispatch(getAllUsers())
  }, [])

  const handleCancel = () => setStaffListModalOpen(false)

  const enrollSelectedStaff = () => {
      dispatch(batchEnroll(course.id, selectedStaff.map(s => s._id)))
      setStaffListModalOpen(false)
  }

  const loadingEnroll = course.loadingEnroll

  const optionMenu = (
    <Menu>
      {privilege === 'student' && <Menu.Item>Review Course</Menu.Item>}
      {(privilege === 'instructor' || privilege === 'admin') && (
        <Menu.Item onClick={removeCourse} danger>
          Delete Course
        </Menu.Item>
      )}
    </Menu>
  )

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedStaff(selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: record._id === user._id, // Column configuration not to be checked
      name: record.name,
    }),
  };


  return (
    <Card
      // hoverable
      bordered={false}
      cover={
        <CourseImage
          image={course.image}
          backgroundColor={course.backgroundColor}
        />
      }
      // onClick={onClick}
    >
      <Card.Meta
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {course.name}
            <span
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              {enrolled && !disableEnroll && (
                <Dropdown.Button
                  placement="bottomCenter"
                  type="text"
                  overlay={optionMenu}
                ></Dropdown.Button>
              )}
            </span>
          </div>
        }
        description={
          <CardContent
            loadingEnroll={loadingEnroll}
            description={course.description}
            enrolled={enrolled}
            onEnroll={handleEnroll}
            onUnenroll={handleUnenroll}
            disableEnroll={disableEnroll}
            privilege={privilege}
            setStaffListModalOpen={setStaffListModalOpen}
            onClick={onClick}
          />
        }
      />

{/* {JSON.stringify({privilege,enrolled,course, user, })}sdfasdf */}

<Modal 
        title="Select Staff to Enroll"
        visible={staffListModalIsOpen}
        onOk={enrollSelectedStaff}
        // onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={enrollSelectedStaff}>
            Submit
          </Button>
        ]}
      >
        <Table
        pagination={false}
        style={{ marginTop: '24px' }}
        rowKey={(staff) => staff._id}
        dataSource={staffList}
        rowSelection={rowSelection}
      >
        <Column
          title="Staff"
          dataIndex={['photo']}
          render={(img) => <Avatar src={img} />}
        />
        <Column
          title="Name"
          dataIndex="name"
        //   render={(time) => {
        //     return time.role
        //     // if (!time) return '-'
        //     // else
        //     //   return DateTime.fromISO(time).toLocaleString(
        //     //     DateTime.DATETIME_MED
        //     //   )
        //   }}
        />
   
        <Column
          title="Department"
          dataIndex="gradeLetter"
          render={(grade) => {
            if (!grade) return '-'
            else return grade
          }}
        />
  

        </Table>
      </Modal>
    </Card>
  )
}

export default CourseCard
