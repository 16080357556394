import { GET_ALL_USERS, LOAD_USERS } from '../actions/staff'

import { notification } from 'antd'
import staffService from '../services/staff'


const staffReducer = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case LOAD_USERS:
      return { data: [], loading: true }
    case GET_ALL_USERS:
      return { data: action.data, loading: false }
    default:
      return state
  }
}

export const getAllUsers = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_USERS })
      const response = await staffService.getAllUsers()

      dispatch({ type: GET_ALL_USERS, data: response })
    } catch (error) {
      console.log(error)
      notification.error({
        message: "Couldn't load users"
      })
    }
  }
}

export default staffReducer
