import axios from 'axios'
import { getAuthHeader } from './config'
import { API_URL } from '../constants/api'

export const baseURL = API_URL + '/users/users'

const getAllUsers = async () => {
  const response = await axios.get(`${baseURL}`, getAuthHeader())
  return response.data
}

const staffService = {
  getAllUsers
}
export default staffService
