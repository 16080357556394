export const LOAD_COURSES = 'LOAD_COURSES'
export const CREATE_COURSE = 'CREATE_COURSE'
export const UPDATE_COURSE = 'UPDATE_COURSE'
export const GET_ALL_COURSES = 'GET_ALL_COURSES'
export const DELETE_COURSE = 'DELETE_COURSE'

export const LOAD_ENROLLMENT = 'LOAD_ENROLLMENT'
export const ENROLL_COURSE = 'ENROLL_COURSE'
export const UN_ENROLL_COURSE = 'UN_ENROLL_COURSE'


export const BATCH_LOAD_ENROLLMENT = 'BATCH_LOAD_ENROLLMENT'
export const BATCH_ENROLL_COURSE = 'BATCH_ENROLL_COURSE'
export const BATCH_UN_ENROLL_COURSE = 'BATCH_UN_ENROLL_COURSE'
