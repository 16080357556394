export const getAuthHeader = () => {
  const token = JSON.parse(localStorage.getItem('hogar-tms-user')).token
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const getMultiPartAuthHeader = () => {
  const token = JSON.parse(localStorage.getItem('hogar-tms-user')).token
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  }
}

export const getS3Credintials = () => ({
  accessKeyId: 'AKIARTJ33EZNPSGZLD4C',
  secretAccessKey: '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'
})
