import { Typography, Table, Avatar } from 'antd'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../components/Spinner'
import { getAllUsers } from '../../reducers/staffReducer'

const { Title } = Typography
const { Column } = Table

const StaffList = (props) => {
  const dispatch = useDispatch()

  const staffList = useSelector((state) => state.staff.data)
  const loading = useSelector((state) => state.staff.loading)

  useEffect(() => {
    dispatch(getAllUsers())
  }, [])


  if (loading) return <Spinner size="large" />

  return (
    <>
      <Title level={3}>Staff List</Title>

      <Table
        pagination={false}
        style={{ marginTop: '24px' }}
        rowKey={(staff) => staff.id}
        dataSource={staffList}
      >
        <Column
          title="Staff"
          dataIndex={['photo']}
          render={(img) => <Avatar src={img} />}
        />
        <Column
          title="Name"
          dataIndex="name"
        //   render={(time) => {
        //     return time.role
        //     // if (!time) return '-'
        //     // else
        //     //   return DateTime.fromISO(time).toLocaleString(
        //     //     DateTime.DATETIME_MED
        //     //   )
        //   }}
        />
        <Column
          title="Designation"
          dataIndex="role"
        //   render={(time) => {
        //     return time.role
        //     // if (!time) return '-'
        //     // else
        //     //   return DateTime.fromISO(time).toLocaleString(
        //     //     DateTime.DATETIME_MED
        //     //   )
        //   }}
        />

        <Column
          title="Department"
          dataIndex="gradeLetter"
          render={(grade) => {
            if (!grade) return '-'
            else return grade
          }}
        />
        <Column
          title="Assigned Courses"
          dataIndex="enrollments"
          render={(enrollments) => {
            if (!enrollments) return '-'
            else return <div style={{textAlign:'center'}}>{enrollments.length}</div>
          }}
        />
        {/* <Column
          title="Enrolled Courses"
          dataIndex="enrollments"
          render={(enrollments) => {
            if (!enrollments) return '-'
            else return <div>{enrollments.length}</div>
          }}
        /> */}
        <Column title="Completed Courses" 
        dataIndex={'achievements'}
render={(achievements) => {
  if (!achievements) return '-'
  else return <div style={{textAlign:'center'}}>{achievements.length}</div>
}}
        />
        </Table>
    </>
  )
}

export default StaffList
