import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import moment from 'moment'
// Import a custom font (optional)
// Font.register({
//   family: 'Open Sans',
//   src: 'https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-U1Ug.woff2'
// });

// Styles for the certificate
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#f9f9f9',
        padding: 30,
      },
      container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        border: '2px solid #ccc',
        padding: 50,
        borderRadius: 10,
        backgroundColor: '#fff',
      },
      logo: {
        width: 150,
        height: 75,
        marginBottom: 20,
      },mr5:{
        marginRight: 10
      }, footer: {fontSize: 10, color: '#000'},
      logoContainer:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      },
      title: {
        fontSize: 28,
        fontWeight: 'bold',
        marginBottom: 10,
        color: '#4A90E2',
      },
      subtitle: {
        fontSize: 16,
        marginBottom: 30,
      },
      name: {
        fontSize: 24,
        marginTop: 20,
        fontWeight: 'bold',
        color: '#333',
      },
      text: {
        fontSize: 18,
        marginTop: 20,
        color: '#555',
      },
      footer: {
        fontSize: 14,
        marginTop: 40,
        textAlign: 'center',
        color: '#777',
      },
      signature: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 50,
        paddingHorizontal: 50,
      },
      signatureBox: {
        width: '40%',
        textAlign: 'center',
      },
});

// Certificate component
const CertificateOfCompletion = ({ info }) => (
    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.container}>
        {/* Logo */}
        <View style={styles.logoContainer}>
        <Image style={styles.logo} src="https://oumc-intranet.vercel.app/img/orlu-united-banner.jpg" />
</View>
        {/* Title */}
        <Text style={styles.title}>Certificate of Completion</Text>

        {/* Subtitle */}
        <Text style={styles.subtitle}>
          This is to certify that
        </Text>

        {/* Recipient's Name */}
        <Text style={styles.name}>{info?.user?.name}</Text>

        {/* Completion Text */}
        <Text style={styles.text}>
          has successfully completed the course
        </Text>

        {/* Course Name */}
        <Text style={styles.text}>{info.course.name}</Text>

        {/* Date */}
        <Text style={styles.text}>on {moment(info.finishedAt).format("DD/MM/YYYY")}</Text>

        {/* Signatures */}
        <View style={styles.signature}>
          <View style={[styles.signatureBox,styles.mr5]}>
            <Text>__________________</Text>
            <Text>        Signature</Text>
          </View>
          <View style={styles.signatureBox}>
            <Text>__________________</Text>
            <Text>Date</Text>
          </View>
        </View>

        {/* Footer */}
        <Text style={styles.footer}>
          Congratulations on your achievement!
        </Text>
      </View>
    </Page>
  </Document>
);

export default CertificateOfCompletion;
